/* COLOR PALETTE */

/* 
Primary:
    Pink: #E367BD
    Robin's Egg Blue: #82D0FF
    Orange: #FEA959
    Yellow: #F5E061

Secondary: 
  Mint Green: #5DB09C
  Greyish-White: #F3F7F8
  Brightest Pink: #FD3DC4
  Light Blue: #C1DCE4
  Golden Yellow: #FBD53E
  Tan: #DCAD79
*/

/*  INTERNAL STYLE RESET */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}


/* ! ! !       T I T L E   P A G E   S T Y L E S       ! ! ! */

/* Title: "Medpramin" */
      #title {
        text-align: center;
        font-size: 14vw;
        margin-top: 0;
        padding-top: 10px;
        color: #388A80;
        font-family: 'MuseoModerno', cursive;
        padding-bottom: 0px;
        margin-bottom: 0px; 
        -webkit-transform:scale(1,1.35); /* Safari and Chrome */
          -moz-transform:scale(1,1.35); /* Firefox */
          -ms-transform:scale(1,1.35); /* IE 9 */
          -o-transform:scale(1,1.35); /* Opera */
          transform:scale(1,1.35); /* W3C */
        -webkit-text-stroke: 0.3px #165141;
        -webkit-text-fill-color: #388A80;
      }


/* Text: "A Painless Prescription Drug Name Generator" */
      #tagline {
        font-family: 'Manrope', sans-serif;
        font-weight: 600;
        text-align: left;
        margin-left: 20px; 
        font-size: 1.5em;
        line-height: 125%;
        color: #F3F7F8;
        padding-top: 0px;
        margin-top: 0px; 
        width: 40vw;
        letter-spacing: 2px;
      }

/* Button: "Learn More" */
      #learn-more {
        font-family: 'Manrope', sans-serif;
        font-weight: 600;
        text-align: left;
        margin-left: 20px; 
        margin-bottom: 20px;
        color: #F3F7F8;
        text-decoration: none;
        background-color: #388A80;
        padding: 8px;
        border-radius: 25px;
        position: fixed;
        bottom: 0;
        border: 1.5px solid #165141;
        font-size: 1.25em;
      }

      #learn-more:hover {
        background-color: #F3F7F8;
        color: #165141;
        border: 1.5px solid #165141;
      }

/* Image: Pink Background */
      .pink-image {
        background-image: url('pinkpills.jpg');
        background-color: #E367BD;
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; 
      }


/* H A M B U R G E R  M E N U */
  
      .fa-prescription-bottle {
        color: #F78740;
        background-color: white;
        border: 2px solid #F78740;
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 15px;
        padding: 5px;
        font-size: 2.5em;
        border-radius: 5px;
      }

      .fa-prescription-bottle:hover {
        color: white;
        background-color: #F78740; 
        border: 2px solid white;
      }

      .hamburger-menu a { 
        text-decoration: none;
      }

      .hamburger-menu p {
        text-align: right;
        margin: 2px;
        padding: 5px;
        font-size: 1.15em;
        color: #F78740;
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
      }

      .hamburger-menu p:hover {
        color: #D18877;
      }

      #icon {
          height: 88px;
          width: 72px;
      }

      .hamburger-menu {
        position: fixed;
        right: 10px;
        bottom: 90px;
        background-color: rgb(255, 252, 250, 100);
        border-radius: 10px;
        border: 2px solid #F78740;
      }




/* ! ! !       A B O U T  P A G E   S T Y L E S       ! ! ! */

/* Image: Assorted Pills Background */
      .about-image {
        background-image: url('blankbottle.jpg');
        background-color: #EED1C9;
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; 
      }

/* Text: "Medpramin is a solutions-based... " */
      .about-text {
        font-family: 'Manrope', sans-serif;
        font-weight: 600;
        text-align: left;
        color: #520300;
        margin-top: 0;
        padding: 20px;
        background-color: rgb(255, 252, 250, 0.5);
        height: 15%;
        font-size: 4vw;
      }

      .about-text2 {
        text-align: center;
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        font-size: 4vw;
        vertical-align: middle;
        position: sticky;
        top: 52%;
        color: #713C3C;
      }

      .about-text3 {
        text-align: center;
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        font-size: 7vw;
        vertical-align: middle;
        position: sticky;
        top: 56%;
        color: #520300;
        text-shadow: 2px 3px 3px rgba(0,0,0,0.32);
      }

      /* Footer */
      .about-footer {
        font-family: 'Manrope', sans-serif;
        font-weight: 300;
        text-align: center;
        font-size: 3vw;
        position: fixed;
        width: 100%;
        bottom: 15px;
        color: #520300;
        text-align: left;  
        padding-left: 10px;       
      }



/* Button: "Generate a Name" */
      .generate-a-name {
        font-family: 'Manrope', sans-serif;
        font-weight: 600;
        text-align: left;
        margin-left: 20px; 
        margin-bottom: 80px;
        color: #174e80;
        text-decoration: none;
        background-color: #C1DCE4;
        padding: 8px;
        border-radius: 25px;
        position: fixed;
        bottom: 0;
        border: 1.5px solid #206DB1;
        font-size: 1.25em;
        padding: 10px;
      }

      .generate-a-name:hover {
        background-color: #206DB1;
        border: 1.5px solid #C1DCE4;
        color: #C1DCE4;
      }

      .compare-names {
        font-family: 'Manrope', sans-serif;
        font-weight: 600;
        text-align: left;
        margin-left: 20px; 
        margin-bottom: 20px;
        color: #174e80;
        text-decoration: none;
        background-color: #C1DCE4;
        padding: 8px;
        border-radius: 25px;
        position: fixed;
        bottom: 0;
        border: 1.5px solid #206DB1;
        font-size: 1.25em;
        padding: 10px;
      }

      .compare-names:hover {
        background-color: #206DB1;
        border: 1.5px solid #C1DCE4;
        color: #C1DCE4;
      }





/* ! ! !       M A I N  P A G E  S T Y L E S       ! ! ! */

/* Image: Blue Background */
      .blue-image {
        background-image: url('bluepills.jpg');
        background-color: #82D0FF;
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; 
      }

/* Text: "Introducing..." */
      #introducing {
        color: #FFFCFA;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 4vw;
        margin: 0px 0px 0px 5px;
      }

/* Title: Randomized Drug Name */
      .random-drug-name {
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        font-size: 8vw;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        color: #F98B98;
        background-color: rgb(255, 252, 250, 0.75);
      }

/* Text: Randomized Drug Facts */
      .random-drug-facts {
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
      }


/* Button: "Generate Name" */
      .most-important-button {
        border: 1px solid #F98B98;
        background-color: #FECECF; 
        color: #F98B98;
        border-radius: 25px;
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        font-size: 1.25em;
        padding: 8px;
      }

      .button-container {
        display: flex;
        justify-content: center;
      }

      .most-important-button:hover {
        background-color: #F98B98; 
        color: #FECECF;
        border: 1px solid #FECECF;
      }


/* Text: Example Drug Facts */
      .drug-details {
        margin-top: 20px;
        color: #F98B98;
        background-color: rgb(255, 252, 250, 0.75);
        height: 35vh;
        text-overflow: clip;
      }

      .drug-details p {
        padding: 10px;
        font-size: 1.15em;
      }

      .drug-details h3 {
        padding-top: 20px;
        padding-left: 10px;
        font-size: 1.25em;
      }
      
      /* Footer */
      .mainpage-footer {
        font-family: 'Manrope', sans-serif;
        font-weight: 300;
        text-align: center;
        font-size: 3vw;
        position: fixed;
        width: 100%;
        bottom: 15px;
        color: #FFFCFA;
        text-align: left;   
        padding-left: 10px; 
      }



/* ! ! !      C O M P A R I S O N  S T Y L E S       ! ! ! */

/* Image: Grey Background */
      .comparison-title {
        text-align: center;
        font-family: 'Manrope', sans-serif;
        font-weight: 900;
        font-size: 5vw;
        color: black; 
      }

      .yellow-image {
        background-image: url('greypills.jpg');
        background-color: #8F989B;
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; 
      }

      .comparison-button {
        background-color:#DEB6A3;
        border: 2px solid black;
        color: black; 
        padding: 8px;
        border-radius: 25px;
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        font-size: 1em;

      }

      .counter {
        position: fixed;
        top: 10px;
        right: 10px;
        text-align: center;
        font-family: 'Manrope', sans-serif;
        font-weight: 900;
        padding: 5px; 
        background-color: #ffd7c4;
        border-radius: 5px;
        border: 2px solid black;
        font-size: 1em;
      }

      .trophy {
        padding-right: 10px;
      }

      .comparison-button:hover {
        color: #DEB6A3;
        border: 1px solid #DEB6A3;
        background-color: #5D6460;
      }

      .comparison-container {
        display: flex;
        justify-content: center;
      }

      .comparison-text {
        text-align: left;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 3.25vw;
        color: black; 
        padding: 5px;
      }

      .drug-names {
        text-align: center;
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        font-size: 6.5vw;
        padding: 5px;
        background-color:rgb(255, 252, 250, 0.80);
      }

      .versus {
        text-align: center;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 1.5em;
        background-color:rgb(255, 252, 250, 0.80);
      }

      .real-product {
        /* color: #17a381; */
        font-weight: 700;
      }

      .fake-product {
        /* color: #b81f34; */
        font-weight: 700;
      }

      .optional-text {
        padding-left: 15px;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 3.25vw;
        position: fixed;
        bottom: 10px; 
        width: 80%;
      }

    /* ! ! !      C R E A T O R   S T Y L E S       ! ! ! */

/* Image: Orange Background */
      .orange-image {
        background-image: url('orangepills.jpg');
        background-color: #FEB267;
        height: 100vh;
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover; 
      }

/* Links to Portfolio Website, LinkedIn, and GitHub */
      .links {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
      }

      .links a {
        text-decoration: none;
        color: black;
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        font-size: 1.25em;
      }

      #link1 {
        grid-row: 1 / 2;
        grid-column: 1 / 2; 
      }

      #link1 a:hover {
        color: #FEB267; 
      }

      #link2 {
        grid-row: 1 / 2;
        grid-column: 2 / 3; 
      }

      #link2 a:hover {
        color: #FEB267; 
      }

      #link3 {
        grid-row: 1 / 2;
        grid-column: 3 / 4; 
      }

      #link3 a:hover {
        color: #FEB267; 
      }

/* Cartoon Image of Taylor */

      #taylor {
        width: 200px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

/* Title: "Meet the Mind behind Medpramin" */

      .meet-the-mind {
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        font-size: 1.5em;
        color: black; 
        text-align: center;
      }

      #medpramin {
        font-family: 'MuseoModerno', cursive;
        font-size: 1.75em;
      }

/* Text: "Taylor is a front-end... " */
      .bio {
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 1em;
        color: black; 
        text-align: left; 
        background-color: rgb(255,255,255,0.75);
        padding: 10px;
      }
      
      /* Footer */
      .creator-footer {
        font-family: 'Manrope', sans-serif;
        font-weight: 300;
        text-align: center;
        font-size: 3vw;
        position: fixed;
        width: 100%;
        bottom: 15px;
        color: black;
        text-align: left;  
        padding-left: 10px; 
      }

/*  ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! RESPONSIVE DESIGN BREAK POINT ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! !*/
  
/* TABLET */

@media only screen and (min-width: 768px) { 

                /* Title: "Medpramin" */
                #title {
                  font-size: 15vw;
                }
                
                /* Text: "A Painless Prescription Drug Name Generator" */
                #tagline {
                  font-weight: 600;
                  margin-left: 70px; 
                  font-size: 3em;
                  letter-spacing: 2px;
                }

                /* Button: "Learn More" */
                #learn-more {
                  font-weight: 700;
                  text-align: left;
                  margin-left: 60px; 
                  margin-bottom: 20px;
                  padding: 12px;
                  font-size: 1.75em;
                }

                /* H A M B U R G E R  M E N U */
  
                .fa-prescription-bottle {
                  position: fixed;
                  margin: 20px 30px;
                  padding: 10px;
                  font-size: 3.5em;
                }

                .hamburger-menu p {
                  margin: 2px;
                  padding: 7.5px;
                  font-size: 1.65em;
                }

                .hamburger-menu {
                  right: 20px;
                  bottom: 110px;
                }

                #icon {
                  height: 88px;
                  width: 72px;
                }

                /* Text: "Medpramin is a... " */
                .about-text {
                  font-family: 'Manrope', sans-serif;
                  font-weight: 600;
                  font-size: 3.15vw;
                }

                .about-text2 {
                  text-align: center;
                  font-family: 'Manrope', sans-serif;
                  font-weight: 700;
                  font-size: 4vw;
                  top: 42%;
                }

                .about-text3 {
                  font-size: 7vw;
                  top: 48%;
                  text-shadow: 4px 5px 6px rgba(0,0,0,0.32);
                }

                /* Footer */
                .about-footer {
                  font-size: 2vw;
                  position: fixed;
                  width: 100%;     
                  text-align: center;
                  padding-left: 0px;
                }

                /* ! ! !       M A I N  P A G E  S T Y L E S       ! ! ! */


                /* Text: "Introducing..." */
                #introducing {
                  font-size: 3vw;
                  margin: 0px 0px 0px 15px;
                }

                /* Title: Randomized Drug Name */
                .random-drug-name {
                  font-size: 6vw;
                  background-color: rgb(255, 252, 250, 0.8);
                }

                /* Text: Randomized Drug Facts */
                .random-drug-facts {
                  font-family: 'Manrope', sans-serif;
                  font-weight: 500;
                }

                /* Button: "Generate Name" */
                .most-important-button {
                  font-weight: 700;
                  font-size: 1.75em;
                  padding: 10px;
                }

                /* Text: Example Drug Facts */
                .drug-details {
                  margin-top: 10px;
                  color: #F98B98;
                  background-color: rgb(255, 252, 250, 0.8);
                  height: 35vh;
                  text-overflow: clip;
                }

                .drug-details p {
                  padding-left: 50px;
                  padding-right: 50px;
                  font-size: 2.5vw;
                }

                .drug-details h3 {
                  padding-top: 20px;
                  padding-bottom: 10px;
                  padding-left: 50px;
                  font-size: 2.5vw;
                }

                /* Footer */
                .mainpage-footer {
                  font-size: 2vw;
                  width: 100%;
                  color: #FFFCFA;  
                  text-align: center;
                  padding-left: 0px;
                }

/* ! ! !      C O M P A R I S O N  S T Y L E S       ! ! ! */

                /* Image: Grey Background */
                .comparison-title {
                  font-size: 5vw;
                  color: #DEB6A3;
                  text-shadow: 2px 3px 3px rgba(0,0,0,0.32);
                }

                .comparison-button {
                  border: 2px solid black;
                  padding: 10px;
                  font-size: 1.25em;
                }

                .comparison-button:hover {
                  border: 2px solid #DEB6A3;
                }

                .comparison-text {
                  text-align: center;
                  font-size: 2vw;
                }

                .drug-names {
                  font-size: 4vw;
                  padding: 10px;
                }

                .versus {
                  font-weight: 500;
                  font-size: 2em;
                }

                .optional-text {
                  font-size: 2.75vw;
                  text-align: left;
                  position: fixed;
                  bottom: 20px;
                  margin-left: 10px;
                  width: 85%;
                }

                .counter {
                  position: fixed;
                  top: 20px;
                  right: 20px;
                  text-align: center;
                  padding: 12px; 
                  font-size: 1.5em;
                }
          
                .trophy {
                  padding-right: 10px;
                }

    /* ! ! !      C R E A T O R   S T Y L E S       ! ! ! */


              /* Cartoon Image of Taylor */

              #taylor {
                width: 200px;
              }

              /* Title: "Meet the Mind behind Medpramin" */

              .meet-the-mind {
                font-size: 2em;
              }

              .links a {
                text-decoration: none;
                color: black;
                font-family: 'Manrope', sans-serif;
                font-weight: 600;
                font-size: 1em;
              }

              #medpramin {
                font-size: 1.75em;
                text-shadow: 2px 3px 3px rgba(0,0,0,0.4);
              }

              /* Text: "Taylor is a front-end... " */
              .bio {
                font-size: 1.25em;
                background-color: rgb(255, 252, 250, 0.85);
                padding: 20px;
              }

              /* Footer */
              .creator-footer {
                font-size: 2vw;
                width: 100%;
                text-align: center;
                padding-left: 0px;
              }

}


/*  ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! RESPONSIVE DESIGN BREAK POINT ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! !*/

/* DESKTOP */

@media only screen and (min-width: 992px) { 

/* ! ! !       T I T L E   P A G E   S T Y L E S       ! ! ! */

                /* Title: "Medpramin" */
                #title {
                  font-size: 16vw;
                }

                /* Text: "A Painless Prescription Drug Name Generator" */
                #tagline {
                  font-weight: 700;
                  margin-left: 60px; 
                  font-size: 3em;
                  width: 30vw;
                  letter-spacing: 2.5px;
                }

                /* Button: "Learn More" */
                #learn-more {
                  font-weight: 700;
                  text-align: left;
                  margin-left: 60px; 
                  margin-bottom: 20px;
                  padding: 12px;
                  font-size: 1.75em;
                }


/* H A M B U R G E R  M E N U */
  
                /* .fa-prescription-bottle {
                  position: fixed;
                  margin: 20px 30px;
                  padding: 10px;
                }

                .hamburger-menu p {
                  margin: 2px;
                  padding: 10px;
                  font-size: 1.75em;
                }

                .hamburger-menu {
                  right: 25px;
                  bottom: 120px;
                } */

                


/* ! ! !       A B O U T  P A G E   S T Y L E S       ! ! ! */


                /* Text: "Medpramin is a... " */
                .about-text {
                  font-family: 'Manrope', sans-serif;
                  font-weight: 600;
                  font-size: 2vw;
                  width: 30vw;
                  height: 30vh;
                  margin-left: 20px;
                  border-radius: 20px;
                  background-color: rgb(255, 252, 250, 0.65);
                }

                .about-text2 {
                  text-align: center;
                  font-family: 'Manrope', sans-serif;
                  font-weight: 700;
                  font-size: 2.5em;
                  top: 40%;
                }

                .about-text3 {
                  font-size: 5vw;
                  top: 46%;
                  text-shadow: 4px 5px 6px rgba(0,0,0,0.32);
                }

                /* Footer */
                .about-footer {
                  font-size: 1.45vw;
                  position: fixed;
                  width: 100%;   
                  text-align: left;  
                  padding-left: 10px;
                }



/* ! ! !       M A I N  P A G E  S T Y L E S       ! ! ! */


                /* Text: "Introducing..." */
                #introducing {
                  font-size: 2vw;
                  margin: 0px 0px 0px 15px;
                }

                /* Title: Randomized Drug Name */
                .random-drug-name {
                  font-size: 6vw;
                  background-color: rgb(255, 252, 250, 0.85);
                }

                /* Text: Randomized Drug Facts */
                .random-drug-facts {
                  font-family: 'Manrope', sans-serif;
                  font-weight: 500;
                }

                /* Button: "Generate Name" */
                .most-important-button {
                  font-weight: 700;
                  font-size: 2em;
                  padding: 12px;
                }

                /* Text: Example Drug Facts */
                .drug-details {
                  margin-top: 20px;
                  color: #F98B98;
                  background-color: rgb(255, 252, 250, 0.85);
                  height: 32vh;
                  text-overflow: clip;
                }

                .drug-details p {
                  padding-left: 50px;
                  padding-right: 50px;
                  font-size: 2vw;
                }

                .drug-details h3 {
                  padding-top: 20px;
                  padding-bottom: 10px;
                  padding-left: 50px;
                  font-size: 2vw;
                }

                /* Footer */
                .mainpage-footer {
                  font-size: 1.45vw;
                  width: 100%;
                  color: #FFFCFA;  
                }
                    

/* ! ! !      C O M P A R I S O N  S T Y L E S       ! ! ! */

                /* Image: Grey Background */
                .comparison-title {
                  font-size: 3.75vw;
                  color: #DEB6A3;
                  text-shadow: 2px 3px 3px rgba(0,0,0,0.32);
                }

                .comparison-button {
                  border: 2px solid black;
                  padding: 12px;
                  font-size: 1.4em;
                }

                .comparison-button:hover {
                  border: 2px solid #DEB6A3;
                }

                .comparison-text {
                  text-align: center;
                  font-size: 1.5vw;
                }

                .drug-names {
                  font-size: 3.5vw;
                  padding: 10px;
                }

                .versus {
                  font-weight: 500;
                }

                .optional-text {
                  font-size: 1.5vw;
                  text-align: left;
                  position: fixed;
                  bottom: 20px;
                  margin-left: 10px;
                  width: 85%;
                }

                .counter {
                  position: fixed;
                  top: 20px;
                  right: 20px;
                  text-align: center;
                  padding: 12px; 
                  font-size: 2em;
                }
          
                .trophy {
                  padding-right: 15px;
                }

    /* ! ! !      C R E A T O R   S T Y L E S       ! ! ! */


              /* Cartoon Image of Taylor */

              #taylor {
                width: 215px;
              }

              .wrapper {
                width: 70%;
              }

              /* Title: "Meet the Mind behind Medpramin" */

              .meet-the-mind {
                font-size: 2vw;
                width: 70%;
              }

              .links a {
                text-decoration: none;
                color: black;
                font-family: 'Manrope', sans-serif;
                font-weight: 700;
                font-size: 1.45vw;
              }

              #medpramin {
                font-size: 3.5vw;
                text-shadow: 2px 3px 3px rgba(0,0,0,0.4);
              }

              /* Text: "Taylor is a front-end... " */
              .bio {
                font-size: 1.5vw;
                background-color: rgb(255, 252, 250, 0.85);
                padding: 20px;
                margin: 0 60px;
                border-radius: 25px;
                width: 65%;
              }

              /* Footer */
              .creator-footer {
                font-size: 1.5vw;
                width: 100%;
              }

    }